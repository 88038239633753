var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col lg:w-1/4 md:w-full" },
                [
                  _c("label", { staticClass: "text-sm mb-2" }, [
                    _c("strong", [_vm._v("Keyword")]),
                  ]),
                  _c("vs-input", {
                    staticClass:
                      "sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                    attrs: { placeholder: "Search..." },
                    model: {
                      value: _vm.searchQuery,
                      callback: function ($$v) {
                        _vm.searchQuery = $$v
                      },
                      expression: "searchQuery",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col lg:w-1/4 md:w-full" },
                [
                  _c("label", { staticClass: "text-sm mb-2" }, [
                    _c("strong", [_vm._v("Category")]),
                  ]),
                  _c("v-select", {
                    staticClass: "w-full",
                    attrs: {
                      value: _vm.selectedCategory,
                      options: _vm.translationCategoryOptions,
                    },
                    on: { input: _vm.setselectedCategory },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "vx-col lg:w-1/2 md:w-full" }, [
                _vm.selectedCategoryFull
                  ? _c("label", { staticClass: "text-sm mb-2" }, [
                      _c("strong", [_vm._v("Category Description")]),
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "mt-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.selectedCategoryFull &&
                        _vm.selectedCategoryFull.description
                    )
                  ),
                ]),
              ]),
            ]),
            _c("div"),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.filteredTranslationList,
                gridOptions: _vm.gridOptions,
                autoGroupColumnDef: _vm.autoGroupColumnDef,
                rowSelection: "single",
                pagination: true,
                context: _vm.context,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: false,
              },
              on: { "selection-changed": _vm.onSelectionChanged },
            }),
          ],
          1
        ),
        _vm.showEditModal
          ? _c("translation-edit-modal", {
              attrs: {
                applicationId: _vm.applicationId,
                translationData: _vm.currentTranslationInEdit,
              },
              on: {
                onSave: _vm.handleSave,
                onCancel: function ($event) {
                  _vm.showEditModal = false
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }