var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex items-center w-full cell-renderer-select-settings-container",
    },
    [
      _c("vs-input", {
        staticClass: "w-full",
        attrs: { value: _vm.inputValue },
        on: { change: _vm.setSetting },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }